<template>
  <div>
    <div class="px-3 py-2">
      <b-row class="align-items-center">
        <b-col sm="3">
          <b-form-input type="text"
                        class="form-control"
                        placeholder="Search by account#"
                        autocomplete="off"
                        v-model="serverParams.keyword"
                        @input="onChange" />
        </b-col>
        <b-col sm="3">
          <b-form-input type="text"
                        placeholder="Name"
                        autocomplete="off"
                        v-model="serverParams.name"
                        @input="onChange" />
        </b-col>
        <b-col sm="3">
          <b-form-input type="text"
                        placeholder="UEN"
                        autocomplete="off"
                        v-model="serverParams.uen"
                        @input="onChange" />
        </b-col>
        <b-col sm="3">
          <b-form-input type="email"
                        placeholder="Email"
                        autocomplete="off"
                        v-model="serverParams.email"
                        @input="onChange" />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="3">
          <b-form-input type="text"
                        placeholder="Address"
                        autocomplete="off"
                        v-model="serverParams.address"
                        @input="onChange" />
        </b-col>
        <b-col sm="3">
          <b-form-input type="text"
                        placeholder="Postal Code"
                        autocomplete="off"
                        v-model="serverParams.postalCode"
                        @input="onChange" />
        </b-col>
        <b-col sm="3">
          <treeselect :options="options.openClose"
                      placeholder="Select Closed Account"
                      v-model="serverParams.deleted"
                      @input="onChange"
                      :clearable="false" />
        </b-col>
        <b-col sm="3">
          <BuildingDropdown :server-params="serverParams"
                            :on-select="onChange"/>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { openClose } from "@/shared/options";
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    props: {
      serverParams: Object,
    },
    components: {
      BuildingDropdown,
    },
    data: () => ({
      options: {
        openClose,
      },
    }),
    methods: {
      onChange() {
        this.serverParams.pageNumber = 1;
        this.$emit("onChange", this.serverParams);
      },
    },
  };
</script>
